import React, { useState } from 'react';

import './App.css';

import emailjs from '@emailjs/browser';

import Swal from 'sweetalert2'

import {
  FaFacebookF, FaInstagram, 
  FaLinkedinIn
} from "react-icons/fa";

function App() {

  const [isLoading, setIsLoading] = useState(false);

  const [contactFormName, setContactFormName] = useState();
  const [contactFormEmail, setContactFormEmail] = useState();
  const [contactFormMessage, setContactFormMessage] = useState();

  const sendContactForm = async () => {
    if (contactFormEmail && contactFormMessage){
      setIsLoading(true);

      const serviceId = 'trustGmailService';
			const adminTemplateId = 'contactFormAdmin';
      // const userTemplateId = 'contactFormUser';
			const publicKey = 'WsXGN9PIP0f6nvLEB';

			const templateParams = {
        fromName: contactFormName ? contactFormName : '',
				fromEmail: contactFormEmail,
        message: contactFormMessage,
			}

			await emailjs.send(serviceId, adminTemplateId, templateParams, publicKey).then(
				(response) => {
					console.log('Email sent', response.status, response.text);
				},
				(error) => {
					console.log('Email failed to send', error);
				},
			);

      // await emailjs.send(serviceId, userTemplateId, templateParams, publicKey).then(
			// 	(response) => {
			// 		console.log('SUCCESS!', response.status, response.text);
			// 	},
			// 	(error) => {
			// 		console.log('FAILED...', error);
			// 	},
			// );

      // await addDoc(collection(db, "mail"), {
      //   to: [
      //     'info@sportleague.net'
      //   ],
      //   message: {
      //     subject: 'You have a new message!',
      //     html: `Hi! You have a new message from ${contactFormEmail} who says:<br/>
      //           ${contactFormMessage}<br/><br/>
      //           Best regards,<br/>
      //           Sport League`
      //   }
      // });

      // await addDoc(collection(db, "mail"), {
      //   to: [
      //     contactFormEmail
      //   ],
      //   message: {
      //     subject: 'Recibimos tu mensaje',
      //     html: `Hola! Gracias por tu interes en Sport League!<br/>
      //           Recibimos tu mensaje y estaremos respondiendote cuanto antes.<br/><br/>
      //           Saludos,<br/>
      //           Sport League`
      //   }
      // });

      Swal.fire({
        text: 'Contact form submitted',
        icon: 'success',
        confirmButtonColor: '#0F4C81',
        confirmButtonText: "Okay",
      });

      setContactFormName('');
      setContactFormEmail('');
      setContactFormMessage('');

      setIsLoading(false);
    } else {
      Swal.fire({
        text: 'Please enter your email and message',
        icon: 'warning',
        confirmButtonColor: '#0F4C81',
        confirmButtonText: "Okay",
      })
    }
  }

  return (
    <div id='app'>
      <div id='heroContainer'>
        <navbar>
          <h1>
            <img src='https://firebasestorage.googleapis.com/v0/b/spranger-ventures.appspot.com/o/Trust%2FTrustLogoTemp.png?alt=media&token=655e87a2-f098-4522-908f-93f51373a67b' alt='logo' />
            TRUST
          </h1>
          {/* <div className='hideOnMobile'>
            <a href='/about-us'>About Us</a>
            <a href='/services'>Services</a>
            <a href='/contact'>Contact</a>
          </div> */}
        </navbar>
        <div className='hrContainer'>
          <hr />
        </div>
        <div id='heroCenteredContainer'>
          <img src='https://firebasestorage.googleapis.com/v0/b/spranger-ventures.appspot.com/o/Trust%2FTrustLogoTemp.png?alt=media&token=655e87a2-f098-4522-908f-93f51373a67b' alt='logo' className='hideOnDesktop' />
          <div>
            <h1>
              Linking global
            </h1>
            <p>
              Achieve seamless transitions and global growth
            </p>
            <button className='primaryBtn'>
              Learn more
            </button>
          </div>
          <img src='https://firebasestorage.googleapis.com/v0/b/spranger-ventures.appspot.com/o/Trust%2FTrustLogoTemp.png?alt=media&token=655e87a2-f098-4522-908f-93f51373a67b' alt='logo' className='hideOnMobile' />
        </div>
        <div id='highlightsBanner'>
          <div className='highlightContainer'>
            <div className='highlightIconContainer'>
              <img src='https://firebasestorage.googleapis.com/v0/b/trustgl.firebasestorage.app/o/assets%2Ficons%2F1.png?alt=media&token=25c0c9c0-89cb-416e-ac9f-717297563777' alt='icon' />
            </div>
            <p>
              Global Reach
            </p>
          </div>
          <div className='highlightContainer middle'>
            <div className='highlightIconContainer'>
              <img src='https://firebasestorage.googleapis.com/v0/b/trustgl.firebasestorage.app/o/assets%2Ficons%2F3.png?alt=media&token=8f0da7dc-5e61-4ea2-a986-61dce1d43f94' alt='icon' />
            </div>
            <p>
              Experience
            </p>
          </div>
          <div className='highlightContainer'>
            <div className='highlightIconContainer'>
              <img src='https://firebasestorage.googleapis.com/v0/b/trustgl.firebasestorage.app/o/assets%2Ficons%2F2.png?alt=media&token=120bce79-2798-49cb-90d5-cc22630c473c' alt='icon' />
            </div>
            <p>
              Commitment
            </p>
          </div>
        </div>
      </div>
      <div id='servicesContainer' className='section'>
        <h2>
          Services
        </h2>
        <p>
          We provide tailored solutions across various sectors. Our services
          are designed to support businesses and individuals in navigating
          global operations and transitions with ease.
        </p>
        <div>
          <div className='serviceContainer'>
            <img src='https://firebasestorage.googleapis.com/v0/b/trustgl.firebasestorage.app/o/assets%2Fbanners%2FtrustPeopleBanner.png?alt=media&token=edeb3104-d8a6-45dd-a9d2-9951ad8b42e2' alt='banner' />
            <div>
              <h3>
                People
              </h3>
              <p>
                Helping businesses build and manage exceptional teams
                through tailored recruitment services and talent acquisition
                strategies.
              </p>
            </div>
          </div>
          <div className='serviceContainer'>
            <img src='https://firebasestorage.googleapis.com/v0/b/trustgl.firebasestorage.app/o/assets%2Fbanners%2FtrustRealEstateBanner.png?alt=media&token=b8406f03-79f9-4854-88ea-3fe8fceb2236' alt='banner' />
            <div>
              <h3>
                Real estate
              </h3>
              <p>
                Offering expert guidance and comprehensive property
                solutions for both individuals and businesses.
              </p>
            </div>
          </div>
          <div className='serviceContainer'>
            <img src='https://firebasestorage.googleapis.com/v0/b/trustgl.firebasestorage.app/o/assets%2Fbanners%2Fpexels-ivan-samkov-8962198.png?alt=media&token=95467dbd-881e-488c-8eb7-9f2d716a8a23' alt='banner' />
            <div>
              <h3>
                Relocation
              </h3>
              <p>
                Assisting expatriates and newcomers in their transition to El
                Salvador with personalized support for housing, settling in
                and others.
              </p>
            </div>
          </div>
          {/* <div className='serviceContainer'>
            <div className='imagePlaceholder'></div>
            <div>
              <h3>
                Solutions
              </h3>
              <p>
                Providing robust testing services to ensure you hire the right
                talent for your needs.
              </p>
            </div>
          </div> */}
          <div className='serviceContainer' style={{marginBottom: 0}}>
            <img src='https://firebasestorage.googleapis.com/v0/b/trustgl.firebasestorage.app/o/assets%2Fbanners%2Fpexels-karolina-grabowska-7876708.png?alt=media&token=a029bc7e-0a19-48f0-9a85-b1180291fb02' alt='banner' />
            <div>
              <h3>
                Consulting
              </h3>
              <p>
                Empowering your team with specialized training, coaching,
                and technical guidance to foster growth and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id='aboutUsContainer' className='section'>
        <h2>
          About us
        </h2>
        <p>
          We’re a dedicated team of professionals passionate about
          creating global opportunities and building bridges between
          talent, businesses, and communities. From assisting
          companies in finding top-tier candidates to supporting
          individuals navigating new beginnings in El Salvador, our
          focus is on making the complex simple and the impossible
          achievable.
        </p>
      </div>
      <div id='contactContainer' className='section'>
        <h2>
          Contact
        </h2>
        <p>
          Feel free to reach out if you have any questions or just want to connect with us.
        </p>
        <div id='contactForm'>
          <input
            type='text'
            placeholder='Name' 
            value={contactFormName}
            onChange={(e) => setContactFormName(e.target.value)}
          />
          <input
            type='email'
            placeholder='Email*'
            value={contactFormEmail}
            onChange={(e) => setContactFormEmail(e.target.value)}
          />
          <textarea
            placeholder='Message*'
            value={contactFormMessage}
            onChange={(e) => setContactFormMessage(e.target.value)}
          ></textarea>
          <button onClick={sendContactForm} className='primaryBtn' disabled={isLoading}>
            {
              isLoading ?
                'Sending...'
              :
                'Send'
            }
          </button>
        </div>
      </div>
      <div className='hrContainer' style={{marginTop: '1rem'}}>
        <hr />
      </div>
      <footer>
        <div id='footerInnerContainer'>
          <h1>
            <img src='https://firebasestorage.googleapis.com/v0/b/spranger-ventures.appspot.com/o/Trust%2FTrustLogoTemp.png?alt=media&token=655e87a2-f098-4522-908f-93f51373a67b' alt='logo' />
            TRUST
          </h1>
          <div id='footerSocials'>
            <a href='https://www.facebook.com/profile.php?id=61574961418230' target='_blank' rel='noreferrer' className='footerSocialIcon'>
              <FaFacebookF />
            </a>
            <a href='https://www.instagram.com/trustlinkingglobal/' target='_blank' rel='noreferrer' className='footerSocialIcon'>
              <FaInstagram />
            </a>
            <a href='https://www.linkedin.com/company/105847953/admin/dashboard/' target='_blank' rel='noreferrer' className='footerSocialIcon'>
              <FaLinkedinIn />
            </a>
          </div>
        </div>
        <p>
          &copy; 2021 TRUST. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default App;
